<template>
  <v-card>
    <v-card-title>
      <span class="title">Product Information</span>
    </v-card-title>

    <v-card-text>
      <div><strong>ID:</strong> {{ product.id }}</div>
      <div><strong>Name:</strong> {{ product.productName }}</div>
      <div><strong>Balance at Date:</strong> {{ product.balance | formatNumber }} ({{ product.balanceDate }})</div>
    </v-card-text>

    <v-card-title>
      <span class="title">Product Rules</span>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model.number="product.maxProductionOverlap"
        label="Max Production Overlap"
        type="number"
        :min="0"
      ></v-text-field>

      <v-text-field
        v-model.number="product.maxDeliveryOverlap"
        label="Max Delivery Overlap"
        type="number"
        :min="0"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" @click="updateRules">Update Rules</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ProductRule",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateRules() {
      // Here you would typically make an API request to update the rules on the server
      // For simplicity, this example just emits an event with the updated product
      this.$emit('update', this.product);
    }
  }
};
</script>

<style scoped>
</style>
